import { Injectable } from "@angular/core";
import { INotificationTemplate, INotificationTriggerParams } from "@core-ports/outputs/NotificationTemplate";
import { INotificationFormInput, INotificationService } from "@core-services/notification";
import { IPushNotification } from "@core-ports/outputs/PushNotification";
import { ErrorService } from "@infra-adapters/services/Error.service";
import { Apollo } from "apollo-angular";
import { Observable, firstValueFrom } from "rxjs";
import { INotificationInput } from "@core-ports/inputs/Notification";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { environment } from "@infra-env/environment";


@Injectable({
    providedIn: 'root'
})
export class NotificationManager implements INotificationService {
    private baseEmailUrl = `${environment.restUrl}/notification/email`;
    private basePushUrl = `${environment.restUrl}/notification/push`;
    private baseUrl = `${environment.restUrl}/notification`;

    notificationForm: FormGroup<INotificationFormInput>;

    constructor(
        private apollo: Apollo,
        private errorService: ErrorService,
        private http: HttpClient,
    ) {
        this.notificationForm = new FormGroup<INotificationFormInput>({
            trigger: new FormControl(null, {
                validators: [Validators.required]
            }),
            email_template: new FormControl(null, {
                validators: [Validators.minLength(3), Validators.required]
            }),
        });
    }

    async getNotificationTemplates(): Promise<Observable<INotificationTemplate[]>> {
        return this.http.get<INotificationTemplate[]>(`${this.baseEmailUrl}/templates`);
    }

    async getNotificationTemplate(id: number): Promise<Observable<INotificationTemplate | null>> {
        return this.http.get<INotificationTemplate>(`${this.baseEmailUrl}/templates/${id}`);
    }

    async createNotification(input: INotificationInput): Promise<INotificationTemplate> {
        return await firstValueFrom(this.http.post<INotificationTemplate>(`${this.baseEmailUrl}/templates`, input));
    }

    async updateNotification(id: number, input: Partial<INotificationInput>): Promise<INotificationTemplate> {
        return await firstValueFrom(this.http.put<INotificationTemplate>(`${this.baseEmailUrl}/templates/${id}`, input));
    }

    async sendTestNotification(
        input: INotificationInput
    ): Promise<void> {
        throw new Error('Method not implemented.');
    }

    async getNotificationTriggerParams(): Promise<Observable<INotificationTriggerParams>> {
        return this.http.get<INotificationTriggerParams>(`${this.baseEmailUrl}/trigger/params`);
    }

    // Push
    async getUnseenNotifications(): Promise<Observable<IPushNotification[]>> {
        return this.http.get<IPushNotification[]>(`${this.basePushUrl}/unseen`);
    }

    async markNotificationsAsRead(ids: number[]): Promise<void> {
        await firstValueFrom(this.http.post(`${this.basePushUrl}/mark-as-read`, ids ));
    }
}